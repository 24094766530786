<template>
  <v-app>
    <NavBar />
    <FlashMessage />
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// internal
import { auth } from '@/providers/firebase';
import '@/styles/styles.scss';
import {
  STAFF_COLLECTION_NAME,
  getCollectionItemByParams,
} from '@/utils/dbUtils';
// components
import NavBar from '@/components/ui/NavBar';
import FlashMessage from '@/components/ui/FlashMessage';

export default {
  name: 'App',
  components: { NavBar, FlashMessage },
  methods: {
    ...mapActions('auth', ['setUser']),
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (!user) this.setUser(null);
      else {
        auth.currentUser.getIdTokenResult().then(() => {
          getCollectionItemByParams({
            collectionName: STAFF_COLLECTION_NAME,
            filters: [{ field: 'email', operator: '==', value: user.email }],
          }).then((resp) => {
            this.setUser({ ...resp[0] });
            if (this.$route.path === '/login') {
              if (this.$route?.query?.to)
                this.$router.push(this.$route.query.to);
              else this.$router.push('/');
            }
          });
        });
      }
    });
  },
};
</script>

<style>
.v-application .text-start {
  text-align: center !important;
}
</style>
