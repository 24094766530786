<template>
  <v-slide-x-transition>
    <div v-if="userEmail" class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            color="secondary"
            :loading="userRefresh"
            v-bind="attrs"
            v-on="on"
          >
            {{ userEmail }}
            <v-icon id="down-icon">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="handleItemSelect(item.value)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-overlay id="reset-overlay" v-model="pwResetDialog">
        <v-card class="reset-overlay-card">
          <v-card-text @click.stop>
            A password reset email will be sent to {{ userEmail }}.
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn class="ma-1" color="white" plain @click.stop="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              class="ma-1"
              color="secondary"
              plain
              @click.stop="handleResetSend"
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-overlay>
      <v-dialog max-width="1200" v-model="profileModal" persistent>
        <StaffForm
          :isOperating="profileModal"
          :itemToEdit="user"
          :profileView="true"
          @close-form="handleProfileModal(false)"
          @refresh-data="handleUserRefresh"
        />
      </v-dialog>
    </div>
  </v-slide-x-transition>
</template>

<script>
// vuex
import { mapActions, mapGetters } from 'vuex';
// internal
import {
  getCollectionItemByParams,
  STAFF_COLLECTION_NAME,
} from '@/utils/dbUtils';
import { doLogout } from '@/utils/authUtils';
import { auth } from '@/providers/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
// components
import StaffForm from '@/components/Staff/StaffForm';

export default {
  name: 'NavBarUser',
  components: {
    StaffForm,
  },
  data() {
    return {
      pwResetDialog: false,
      userRefresh: false,
      itemToEdit: {},
      items: [
        { title: 'Profile', value: 'profile' },
        { title: 'Reset Password', value: 'changePW' },
        { title: 'Logout', value: 'logout' },
      ],
      rules: {
        required: (value) => !!value || 'Required',
      },
    };
  },
  methods: {
    ...mapActions('auth', ['setUser', 'handleProfileModal']),
    ...mapActions('flashMessage', ['handleFlash']),
    handleItemSelect(value) {
      if (value === 'logout') this.logout();
      else if (value === 'changePW') this.pwResetDialog = true;
      else if (value === 'profile') this.handleProfileModal(true);
    },
    logout() {
      doLogout().then(() => {
        this.setUser(null);
        this.$router.push('login');
      });
    },
    handleResetSend() {
      sendPasswordResetEmail(auth, this.userEmail)
        .then(() => {
          this.handleFlash({ success: true, show: true });
          this.pwResetDialog = false;
          this.logout();
        })
        .catch((error) =>
          this.handleFlash({
            response: error.message,
            success: false,
            show: true,
          })
        );
    },
    handleUserRefresh(handleProfileChange, userData, modifiedKeys) {
      this.handleProfileModal(false);
      this.userRefresh = true;
      if (handleProfileChange) {
        getCollectionItemByParams({
          collectionName: STAFF_COLLECTION_NAME,
          filters: [{ field: 'email', operator: '==', value: userData.email }],
        }).then((resp) => {
          this.setUser({ ...resp[0] });
          this.userRefresh = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['user', 'profileModal']),
    userEmail() {
      return this.user?.email;
    },
  },
};
</script>

<style lang="scss" scoped>
#reset-overlay {
  position: fixed;
  height: 100vh;
  .reset-overlay-card {
    width: 80%;
  }
}

::v-deep {
  .v-btn__content {
    line-height: 1;
  }
}

#down-icon {
  margin-top: 1px;
}
</style>
