<template>
  <div class="assignment-dashboard my-10 px-2">
    <div
      class="d-flex justify-space-between mb-4"
      :style="{ position: 'relative' }"
    >
      <!-- left -->
      <div class="d-flex">
        <!-- new assignment -->
        <div v-if="isAdminMode" class="mr-2 text-center">
          <span class="overline"> New </span>
          <div>
            <v-btn
              min-width="40px"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="showTableLoader"
              @click="handleTask({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- add filters -->
        <div class="text-center">
          <span class="overline"> Add </span>
          <div>
            <v-badge
              bordered
              color="accent"
              overlap
              :value="filtersBadge"
              :content="filtersBadge"
            >
              <v-btn
                min-width="40px"
                height="38px"
                color="#494949"
                class="px-0"
                @click="filtersDialog = true"
              >
                <v-icon color="white">mdi-filter-plus</v-icon>
              </v-btn></v-badge
            >
          </div>
        </div>

        <v-dialog
          content-class="filters"
          transition="dialog-top-transition"
          max-width="1500px"
          v-model="filtersDialog"
          hide-overlay
          persistent
          no-click-animation
        >
          <v-card
            class="d-flex justify-space-around flex-wrap px-6 pt-md-2 pt-lg-4 pb-md-4 pb-lg-6"
          >
            <!-- pay period filter -->
            <div>
              <span class="overline"> Pay Period </span>
              <div class="d-flex">
                <v-autocomplete
                  class="assignment-dashboard__month-selector mr-1"
                  :items="monthOptions"
                  v-model="filters.ppm"
                  solo
                  dense
                  hide-details
                ></v-autocomplete>
                <v-autocomplete
                  class="assignment-dashboard__year-selector"
                  :items="yearOptions"
                  v-model="filters.ppy"
                  solo
                  dense
                  hide-details
                >
                </v-autocomplete>
              </div>
            </div>

            <!-- delivery date filter -->
            <div class="ml-2 text-center">
              <span class="overline">
                {{ isAdminMode ? 'Delivery Date' : 'Due Date' }}
              </span>
              <div class="d-flex align-end">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  solo-inverted
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.filterDeliveryDate"
                      class="assignment-dashboard__date-selector"
                      solo
                      dense
                      readonly
                      hide-details
                      clearable
                      :disabled="showTableLoader"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="filters.filterDeliveryDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.filterDeliveryDate"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <!-- logs filter -->
            <div class="ml-2 text-center">
              <span class="overline"> Log Date(s) </span>
              <div class="d-flex align-end">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  solo-inverted
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterLogDatesRange"
                      class="assignment-dashboard__project-selector"
                      solo
                      dense
                      readonly
                      hide-details
                      clearable
                      :disabled="showTableLoader"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="filters.filterLogDatesRange = []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.filterLogDatesRange"
                    range
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <!-- project filter -->
            <div v-if="isAdminMode" class="ml-2 text-center">
              <span class="overline"> Project </span>
              <div>
                <v-autocomplete
                  class="assignment-dashboard__project-selector"
                  :items="projectsList"
                  v-model="filters.filterProject"
                  solo
                  dense
                  hide-details
                ></v-autocomplete>
              </div>
            </div>

            <!-- person filter -->
            <div v-if="isAdminMode" class="ml-2 text-center">
              <span class="overline"> Person </span>
              <div class="d-flex">
                <v-autocomplete
                  class="assignment-dashboard__person-selector mr-2"
                  :items="staffList"
                  :item-text="fullName"
                  return-object
                  v-model="filters.filterPerson"
                  solo
                  dense
                  hide-details
                  :loading="showTableLoader"
                ></v-autocomplete>
              </div>
            </div>

            <!-- status filter -->
            <div class="ml-2 text-center">
              <span class="overline"> Status </span>
              <div class="d-flex">
                <v-autocomplete
                  class="assignment-dashboard__status-selector"
                  :items="statusOptions"
                  v-model="filters.filterStatus"
                  item-text="value"
                  solo
                  dense
                  hide-details
                >
                </v-autocomplete>
              </div>
            </div>

            <!-- search -->
            <div class="ml-4 text-center">
              <span class="overline"> Search </span>
              <div>
                <v-text-field
                  v-model="filters.filterSearch"
                  class="assignment-dashboard__search-selector"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  dense
                  hide-details
                  clearable
                  :disabled="showTableLoader"
                  @click:prepend-inner="applySearch(false)"
                  @click:clear="applySearch(true)"
                  @keydown.enter="applySearch(false)"
                />
              </div>
            </div>

            <v-card-actions class="ml-auto pa-0 pt-2 align-end">
              <v-btn
                color="secondary"
                :disabled="!filtersBadge"
                @click="clearFilter"
                >Clear Filters</v-btn
              >
              <v-btn color="#494949" dark @click="filtersDialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div>
            <v-btn
              min-width="40px"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :disabled="!filtersBadge"
              @click="clearFilter"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- task form -->
        <v-dialog
          max-width="1200"
          :value="taskModal"
          @click:outside="taskModal = false"
          @keydown.esc="taskModal = false"
        >
          <ADNewAssignmentItem
            :itemToEdit="taskToEdit"
            @tasks-table-refresh="refreshData"
          />
        </v-dialog>

        <!-- salary reports -->
        <div v-if="isAdminMode" class="text-center ml-2">
          <span class="overline"> Rep </span>
          <div>
            <v-dialog v-model="reportDialog" persistent max-width="490">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="40px"
                  height="38px"
                  class="elevation-2 px-0"
                  :loading="showTableLoader"
                  :disabled="!filters.ppm || !filters.ppy"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#000000de">mdi-currency-usd</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5"> Salary reports </v-card-title>
                <v-card-text
                  >You are about to generate a salary reports for the team and
                  send it via Slack bot. Proceed?</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="reportDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="generateSalaryReports(false)"
                  >
                    Send
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="generateSalaryReports(true)"
                  >
                    Send to Admins only
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-center my-0">
        <!-- search -->
        <span class="overline">Search</span>
        <div>
          <v-text-field
            v-model="filters.filterSearch"
            append-outer-icon="mdi-magnify"
            class="assignment-dashboard__search-selector"
            solo
            dense
            hide-details
            clearable
          />
        </div>
      </div>
    </div>
    <!-- log form -->
    <v-dialog
      max-width="1200"
      :value="logModal"
      @click:outside="logModal = false"
      @keydown.esc="logModal = false"
    >
      <LogForm
        :itemToEdit="logToEdit"
        :parent="logParent"
        @tasks-table-refresh="refreshData"
      />
    </v-dialog>
    <!-- table -->
    <v-card class="assignment-dashboard__table-card">
      <v-skeleton-loader
        v-if="showTableLoader"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <ADTable
        ref="dashboardTable"
        v-if="tableData && !showTableLoader"
        :tableData="tableData"
        :isAdminMode="isAdminMode"
        :filterPaymentMonth="filters.ppm"
        :filterPaymentYear="filters.ppy"
        :filterDeliveryDate="filters.filterDeliveryDate"
        :filterSearch="filters.filterSearch"
        :filterProject="filters.filterProject ? filters.filterProject : ''"
        :filterPerson="filters.filterPerson ? filters.filterPerson : ''"
        :filterStatus="filters.filterStatus ? filters.filterStatus : ''"
        @call-log-form="handleLog"
        @edit-log="handleLog"
        @edit-task="handleTask"
      />
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from 'vuex';
// libraries
import axios from 'axios';
// internal
import {
  getAllCollectionItems,
  getCollectionItemByParams,
  STAFF_COLLECTION_NAME,
  TASKS_COLLECTION_NAME,
  LOGS_COLLECTION_NAME,
} from '@/utils/dbUtils';
import { sendInvoices } from '@/utils/slackUtils';
import { timeUnits } from '@/utils/mixins';
import { STATUS_COLORS } from '@/utils/constants';
// components
import ADTable from '@/components/AssignmentDashboard/ADTable';
import ADNewAssignmentItem from '@/components/AssignmentDashboard/ADNewAssignmentItem';
import LogForm from '@/components/AssignmentDashboard/LogForm';

export default {
  name: 'AssignmentDashboard',
  components: {
    ADTable,
    ADNewAssignmentItem,
    LogForm,
  },
  data() {
    return {
      showTableLoader: true,
      tableData: null,
      filterEmail: false,
      filtersDialog: false,
      filters: {
        ppm: null,
        ppy: null,
        filterDeliveryDate: null,
        filterLogDatesRange: [],
        filterSearch: null,
        filterProject: null,
        filterPerson: null,
        filterStatus: null,
        filterSearch: null,
      },
      earningsMode: null,
      taskModal: false,
      taskToEdit: {},
      logModal: false,
      logToEdit: {},
      logParent: null,
      reportDialog: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin', 'isPm']),
    ...mapGetters('assignment', ['statusOptions', 'staffList', 'projectsList']),
    isAdminMode() {
      return !this.filterEmail;
    },
    filtersBadge() {
      return Object.entries(this.filters)
        .map((f) => f[1])
        .filter((f) => (Array.isArray(f) ? f.length : !!f)).length;
    },
    ppm() {
      return this.filters.ppm;
    },
    ppy() {
      return this.filters.ppy;
    },
    filterLogDatesRange: {
      get: function () {
        return this.filters.filterLogDatesRange.join(' - ');
      },
      set: function (newVal) {
        return (newVal ?? []).join(' - ');
      },
    },
  },
  watch: {
    ppm(newVal) {
      if ((newVal && this.filters.ppy) || (!newVal && !this.filters.ppy))
        this.getTableData();
    },
    ppy(newVal) {
      if ((newVal && this.filters.ppm) || (!newVal && !this.filters.ppm))
        this.getTableData();
    },
    filterLogDatesRange() {
      this.getTableData();
    },
  },
  mixins: [timeUnits],
  mounted() {
    this.handleUser();
    this.getStatuses();
  },
  methods: {
    ...mapActions('assignment', ['setCommonData']),
    ...mapActions('flashMessage', ['handleFlash']),
    fullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    async getTableData() {
      this.showTableLoader = true;
      const tasks = this.isAdminMode
        ? await getAllCollectionItems(TASKS_COLLECTION_NAME)
        : await getCollectionItemByParams({
            collectionName: TASKS_COLLECTION_NAME,
            param: 'assignee',
            value: this.user.id,
          });
      const logsPromises = tasks.map(
        (t) =>
          new Promise(async (resolve) => {
            let filters = [{ field: 'parent', operator: '==', value: t.id }];

            if (this.filters.filterLogDatesRange.length) {
              filters.push({
                field: 'date',
                operator: `${this.filters.filterLogDatesRange[1] ? '>' : '='}=`,
                value: this.filters.filterLogDatesRange[0],
              });
              if (this.filters.filterLogDatesRange[1])
                filters.push({
                  field: 'date',
                  operator: '<=',
                  value: this.filters.filterLogDatesRange[1],
                });
            } else if (this.filters.ppm && this.filters.ppy) {
              const lastDayOfMonth = this.$moment(
                `${this.filters.ppy}-${this.filters.ppm}-01`
              )
                .endOf('month')
                .format('DD');
              filters.push(
                {
                  field: 'date',
                  operator: '>=',
                  value: `${this.filters.ppy}-${this.$moment(
                    this.filters.ppm,
                    'MMMM'
                  ).format('MM')}-01`,
                },
                {
                  field: 'date',
                  operator: '<=',
                  value: `${this.filters.ppy}-${this.$moment(
                    this.filters.ppm,
                    'MMMM'
                  ).format('MM')}-${lastDayOfMonth}`,
                }
              );
            }

            const fullLogs = await getCollectionItemByParams({
              collectionName: LOGS_COLLECTION_NAME,
              filters,
            });
            resolve({
              ...t,
              fullLogs,
            });
          })
      );
      this.tableData = await Promise.all(logsPromises);
      if (this.isAdminMode) {
        const staff = await getAllCollectionItems(STAFF_COLLECTION_NAME);
        staff.sort((a, b) => a.firstName.localeCompare(b.firstName));
        this.setCommonData({
          field: 'staffList',
          data: staff,
        });
        this.tableData = this.tableData.map((el) => {
          const assignee = staff.find((s) => s.id === el.assignee);
          return {
            ...el,
            assignee,
          };
        });
      }
      this.showTableLoader = false;
    },
    clearFilter() {
      Object.keys(this.filters).forEach(
        (k) => (this.filters[k] = Array.isArray(this.filters[k]) ? [] : null)
      );
    },
    getStatuses() {
      const data = [
        'To Do',
        'In Progress',
        'Ready For Testing',
        'In Testing',
        'Reopened',
        'Done',
        'On Hold',
      ];
      const statusOptions = data.map((el) => ({
        value: el,
        color: STATUS_COLORS.find((color) => color.statuses.includes(el))
          ?.value,
      }));
      this.setCommonData({ field: 'statusOptions', data: statusOptions });
    },
    handleUser() {
      this.filterEmail = this.isAdmin || this.isPm ? false : this.user.email;
      this.getTableData();
    },
    handleLog(logItem, tableRow) {
      this.logToEdit = { ...logItem };
      this.logParent = { ...tableRow };
      this.logModal = true;
    },
    handleTask(taskItem) {
      this.taskToEdit = { ...taskItem };
      this.taskModal = true;
    },
    refreshData() {
      this.getTableData();
      this.logModal = this.taskModal = false;
    },
    async generateSalaryReports(adminOnly) {
      const admins = this.staffList
        .filter((el) => el.roles.includes('Admin'))
        .map((el) => el.slackId);

      const rates = await axios.get(
        'https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json'
      );
      const usdRate = rates?.data?.find((r) => r.cc === 'USD')?.rate;

      const filteredData =
        this.$refs.dashboardTable._computedWatchers.filteredData.value;

      const assignees = [...new Set(filteredData.map((el) => el.assignee))];

      const reportsData = assignees.map((assignee) => {
        let reportData = {
          assignee,
          projects: [],
          receivers: [...admins],
        };
        if (!adminOnly) reportData.receivers.push(assignee.slackId);

        const assigneeTasks = filteredData.filter(
          (el) => el.assignee.id === assignee.id
        );

        const assigneeProjects = [
          ...new Set(assigneeTasks.map((el) => el.project)),
        ];
        assigneeProjects.forEach((proj) => {
          const projectTasks = assigneeTasks.filter(
            (el) => el.project === proj
          );

          const projectLogs = projectTasks
            .map((el) =>
              el.fullLogs
                .filter(
                  (log) =>
                    this.$moment(log.date, 'YYYY-MM-DD').format('MM') ===
                    this.$moment(this.filters.ppm, 'MMMM').format('MM')
                )
                .reduce((a, b) => +a + +(b.time || 0), 0)
            )
            .reduce((a, b) => a + b, 0);

          reportData.projects.push({
            [proj]: projectLogs,
          });
        });

        return reportData;
      });
      await sendInvoices({
        reportsData,
        usdRate,
        payPeriod: `${this.filters.ppm} ${this.filters.ppy}`,
      });
      this.reportDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment-dashboard {
  max-width: 1500px;
  margin: 0 auto;

  &__month-selector {
    width: 150px;
  }

  &__year-selector {
    width: 120px;
  }

  &__date-selector {
    width: 150px;
  }

  &__project-selector {
    width: 250px;
  }

  &__person-selector {
    width: 230px;
  }

  &__status-selector {
    width: 200px;
  }

  &__search-selector {
    width: 450px;
  }
}

::v-deep {
  .filters {
    position: absolute;
    top: 20px;
  }
}
</style>
