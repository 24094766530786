<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Name"
            v-model="task.name"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            outlined
            label="Project"
            :items="projectsList"
            v-model="task.project"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="2"
            clear-icon="mdi-close-circle-outline"
            label="Description"
            v-model="task.desc"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="task.dueDate"
                label="Due date"
                prepend-icon="mdi-calendar-clock"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="task.dueDate"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-menu
            ref="menu"
            v-model="timeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="task.dueTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="task.dueTime"
                label="Due time"
                prepend-icon="mdi-clock-time-four-outline"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="timeMenu"
              v-model="task.dueTime"
              full-width
              format="24hr"
              @click:minute="$refs.menu.save(task.dueTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Estimate"
            type="number"
            v-model="task.estimate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="Assignee"
            :items="staffList"
            :item-text="fullName"
            return-object
            v-model="task.assignee"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="Priority"
            :items="prioritiesList"
            v-model="task.priority"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="Status"
            :items="statusOptions"
            item-text="value"
            v-model="task.status"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteTask"> Yes </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="taskHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="taskHandling"
        :disabled="deleteConfirmation"
        @click="handleTask"
      >
        {{ isEditMode ? 'Update' : 'Save' }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
// internal
import {
  processDocument,
  deleteDocument,
  getCollectionItemByParams,
  TASKS_COLLECTION_NAME,
  LOGS_COLLECTION_NAME,
} from '@/utils/dbUtils';
import { rules } from '@/utils/mixins';

export default {
  name: 'ADNewAssignmentItem',
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateMenu: false,
      timeMenu: false,
      task: {
        assignee: null,
        desc: null,
        dueDate: null,
        dueTime: null,
        estimate: null,
        name: null,
        priority: null,
        project: null,
        status: null,
      },
      prioritiesList: ['Low', 'Medium', 'High'],
      valid: true,
      taskHandling: false,
      deleteConfirmation: false,
    };
  },
  mixins: [rules],
  computed: {
    ...mapGetters('assignment', ['statusOptions', 'staffList', 'projectsList']),
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  mounted() {
    this.dataViewsHandler();
  },
  methods: {
    async dataViewsHandler() {
      this.deleteConfirmation = false;
      this.task = { ...this.itemToEdit };
      if (!this.isEditMode) {
        await this.$refs.form.reset();
        this.task.priority = 'Medium';
        this.task.status = 'To Do';
      }
    },
    fullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    async handleTask() {
      this.taskHandling = true;
      if (this.$refs.form.validate()) {
        await processDocument({
          document: { ...this.task, assignee: this.task.assignee.id },
          collectionName: TASKS_COLLECTION_NAME,
        });
        this.taskHandling = false;
        this.$emit('tasks-table-refresh');
      } else this.taskHandling = false;
    },
    async deleteTask() {
      this.deleteConfirmation = false;
      this.taskHandling = true;
      await deleteDocument({
        docId: this.itemToEdit.id,
        collectionName: TASKS_COLLECTION_NAME,
      });
      const logs = await getCollectionItemByParams({
        collectionName: LOGS_COLLECTION_NAME,
        filters: [
          { field: 'parent', operator: '==', value: this.itemToEdit.id },
        ],
      });
      const logsToDelete = logs.map((el) =>
        deleteDocument({
          docId: el.id,
          collectionName: LOGS_COLLECTION_NAME,
        })
      );
      await Promise.all(logsToDelete);
      this.taskHandling = false;
      this.$emit('tasks-table-refresh');
    },
  },
};
</script>

<style lang="scss" scoped></style>
